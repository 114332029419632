import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

type Props = {
  pageTitle?: string;
  pageDescription?: string;
}

const SEO: React.FC<Props> = props => {
  const data = useStaticQuery<GatsbyTypes.SeoSiteMetadataQueryQuery>(graphql`
    query SeoSiteMetadataQuery {
      site {
        siteMetadata{
          title
          lang
          description
        }
      }
    }
  `);

  const title = props.pageTitle
    ? `${props.pageTitle} | ${data.site?.siteMetadata?.title}`
    : data.site?.siteMetadata?.title;

  const description = props.pageDescription || data.site?.siteMetadata?.description;

  return (
    <Helmet>
      <html lang={data.site?.siteMetadata?.lang} />
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
  );
};

export default SEO;
